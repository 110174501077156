import styled from '@emotion/styled';
import { Colors, viewportWidthSmall, viewportWidthMedium } from '@paddl/storybook';

// eslint-disable-next-line max-len
const landscapeBased64 = 'PHN2ZyB3aWR0aD0iMTQ4MyIgaGVpZ2h0PSIxMDkiIHZpZXdCb3g9IjAgMCAxNDgzIDEwOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC4xIj4KPHBhdGggZD0iTTY2NC4yNyA2MS4xOTc4TDYxOS41IDEwOC45NDFMNTAwIDEwOC45NDFMMjc4LjYyIDEwOC45NDFMMjE1Ljc3NSA3Mi41MzUxTDI3NS40NDggNDkuMjAzNUMzMTguOTEzIDMyLjIyNjMgMzY0LjE5OSAyNi45ODc5IDQwOS4wMDcgMzMuNzQ3Mkw0MzYuNDQ4IDM3Ljg3Nkw0NDAuNTk0IDM4LjQ4NzRDNDUwLjkwNiA0MC4wNDczIDQ2MS4yNzkgNDAuMjkxNiA0NzEuNjAzIDM5LjI4MzFDNDc4LjY3NCAzOC41Njg0IDQ4NS43NDMgMzcuMjU4NiA0OTIuNzIzIDM1LjM1NEM1MDYuODU2IDMxLjQ4MTUgNTIxLjE3IDI5LjUxOSA1MzUuNDkgMjkuNDY3QzU1MS44MDYgMjkuNDA3OCA1NjguMTMyIDMxLjgyMjkgNTg0LjE4NCAzNi43NDQ3TDY2NC4yNyA2MS4xOTc4WiIgZmlsbD0iIzAwNkRFRiIvPgo8cGF0aCBkPSJNMCAxMkMxNjIuMTg3IDI5LjA1NjYgMzE3Ljk5OSA1OC41MTE2IDQ2MS44NDMgOTkuMzE3M0w0OTYgMTA5SDBWMTJaIiBmaWxsPSIjNUVCMkZGIi8+CjxwYXRoIGQ9Ik05NDUgMTA5SDEwMzkuODRIMTEyMC4zNkgxMzk1QzEzOTUgMTA5IDEzNDUuOTkgNzAuOTUyMiAxMzA3LjMyIDYwLjI5ODlDMTI2OC42NSA0OS42NDU2IDEyMjIuNzQgNDEuODA5OCAxMTc4Ljk5IDQ2LjczNTNMMTE1Mi4xOSA0OS43NDQxTDExNDguMTQgNTAuMTg5OEMxMTM4LjA3IDUxLjMyNjUgMTEyNy45NCA1MS41MjcxIDExMTcuODUgNTAuODM2MUMxMTEwLjk0IDUwLjM0NTggMTEwNC4wMyA0OS40MzIxIDEwOTcuMiA0OC4wOTQ4QzEwODMuMzggNDUuMzc1OCAxMDY5LjM5IDQ0LjAxNjMgMTA1NS40IDQ0LjAxNjNDMTAzOS40NiA0NC4wMTYzIDEwMzMuOTEgNDMuMDQwMiAxMDAxLjMxIDU2LjIxNTRDOTY4LjcxIDY5LjM5MDcgOTQ1IDEwOSA5NDUgMTA5WiIgZmlsbD0iIzFBOTFGRiIvPgo8cGF0aCBkPSJNMTQ4MyAxMkMxMzU3LjQ0IDI5LjA1NjYgMTIzNi44MSA1OC41MTE2IDExMjUuNDQgOTkuMzE3M0wxMDk5IDEwOUgxNDgzVjEyWiIgZmlsbD0iIzVFQjJGRiIvPgo8cGF0aCBkPSJNMTAwNyAxMDlINTQ1QzYwMy4yODQgNTEuMTMwMSA2NzguMTI5IDE5LjY5MzIgNzU0LjM5OSAxNC43MDhDNzcwLjk2IDEzLjYyOTEgNzg3LjU3OCAxMy43Nzc5IDgwNC4xMjEgMTUuMTkxNkM4NzguMTIzIDIxLjQ5NzYgOTUwLjM4MyA1Mi43NjcxIDEwMDcgMTA5WiIgZmlsbD0iIzVFQjJGRiIvPgo8L2c+Cjwvc3ZnPgo=';

export const Page404Wrapper = styled.div`
  background: ${`url('data:image/svg+xml;base64,${landscapeBased64}') repeat-x bottom,
    linear-gradient(180deg, ${Colors.white} 35%, ${Colors.sec_purple_lighter} 100%)`};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${viewportWidthSmall}) {
    padding: 80px 32px 120px;
    height: calc(100vh - 62px - 40px - 64px - 83px);
  }

  @media (min-width: ${viewportWidthMedium}) {
    padding: 60px 64px 100px;
    height: calc(100vh - 62px - 32px - 32px - 40px);
  }
`;
