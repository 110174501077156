import React from 'react';
import { NotFound } from '@paddl/storybook';
import { Layout } from '../components/Layout/Layout';
import { Page404Wrapper } from '../styles/pages/404.styles';

const Page404 = () =>
  <Layout
    pageTitle="Page not found"
    pageMetaImageUrl={`${process.env.PUBLIC_URL}/assets/social-share.png`}
    pageDescription="Please double check the url or reach out to our support team."
    data-test-id="404-page"
  >
    <Page404Wrapper>
      <NotFound />
    </Page404Wrapper>
  </Layout>;

export default Page404;
